@use "src/sedestral/sass/mixins/texts";
@use "src/sedestral/sass/extends/boxs";
@use "src/sedestral/sass/icons/icons";

.componentClusterHoneycomb {
  width: 100%;
  height: 100%;
  background: var(--white);

  .map {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;

    &.hideTexts {
      text {
        display: none;
      }
    }
  }

  .grid {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    padding: 15px;
    background: #cfcfdb17;

    .dot {
      height: 4px;
      width: 4px;
      background: var(--grey-sur);
      border-radius: 100%;
      flex: 0 0 4px;
    }
  }

  .zoom {
    position: absolute;
    right: 10px;
    top: 10px;
    background: var(--white);
    display: flex;
    align-items: center;
    padding: 10px;
    font-size: 15px;
    gap: 10px;
    justify-content: space-between;
    width: 110px;

    @include boxs.sur;

    .value {
      font-size: 15px;
    }

    .button {
      height: 26px;
      width: 26px;
      border-radius: 100%;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background: var(--grey-sur);
      }

      &.plus {
        .icon {
          @include icons.ico("black-plus");
        }
      }

      &.less {
        .icon {
          @include icons.ico("black-less");
        }
      }

      .icon {
        height: 12px;
        width: 12px;
        background: blue;
      }
    }
  }

  .details {
    position: absolute;
    left: 10px;
    top: 10px;

    .block {
      height: fit-content;
      width: 254px;
      padding: 15px;
      margin-bottom: 15px;

      @include boxs.sur;

      &.keyword {
        top: 230px;
        height: fit-content;
      }
    }
  }
}