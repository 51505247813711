@use "src/sedestral/sass/mixins/texts";
@use "src/sedestral/sass/extends/boxs";
@use "src/sedestral/sass/icons/icons";

.componentHoneycombFilters {
  .top {
    margin-bottom: 15px;

    .by {
      color: var(--grey-text-dark);
      font-size: 14px;
      margin-bottom: 10px;
    }

    .selectContainer {
      height: 40px;

      div[data-selectbox] {
        border-width: 1px;
      }
    }
  }

  .legend {
    .group {
      display: flex;
      gap: 10px;
      flex-wrap: wrap;

      &.align {
        align-items: center;
      }

      .entry {
        display: flex;
        align-items: center;
        gap: 4px;
        font-size: 13px;

        &.ranked {
          .icon {
            @include icons.ico("colored-topical-map-ranked");
          }
        }

        &.published {
          .icon {
            @include icons.ico("colored-topical-map-published");
          }
        }

        &.exported {
          .icon {
            @include icons.ico("colored-topical-map");
          }
        }

        &.notCovered {
          .icon {
            @include icons.ico("colored-topical-map-white");
          }
        }

        &.coverageDraft {
          .icon {
            @include icons.ico("colored-topical-map-draft");
          }
        }

        .icon {
          height: 16px;
          width: 16px;
        }
      }

      .progression {
        display: flex;
        align-items: center;
        justify-content: center;
        pointer-events: none;
        width: 100%;
        position: absolute;
        left: 0;

        .icon {
          height: 60px;
          width: 60px;
          position: absolute;

          @include icons.ico("colored-topical-map-progress");
        }
      }

      .label {
        font-size: 13px;
        color: var(--grey-text-dark);
        text-transform: uppercase;

        &.right {
          margin-left: auto;
        }
      }
    }
  }
}