@use "../../../../../../sedestral/sass/mixins/texts";
@use "../../../../../../sedestral/sass/mixins/positioning";
@use "../../../../../../sedestral/sass/extends/buttons";

.globalDateIntervalPicker {

  .topSide {
    @include positioning.clear;


    .purposes {
      height: 100%;
      width: 200px;
      float: left;

      .purposeEntry {
        cursor: pointer;
        height: 17px;
        padding: 7px 10px;
        width: calc(100% - 20px);
        border-radius: 8px;
        margin-bottom: 5px;
        font-size: 14px;

        @include texts.font("semi-bold");

        &:hover {
          background: var(--grey-sur);
        }

        &.selected {
          background: var(--grey-sur);
        }

      }
    }

    .pickerContainer {
      height: 100%;
      width: calc(100% - 210px);
      float: right;
    }
  }

  .buttons {
    margin-top: 6px;

    .button {
      @include buttons.round;
      @include texts.font("semi-bold");

      font-size: 15px;
      background: var(--grey-sur);
      padding: 8px 20px;
      width: fit-content;
      float: right;
      border-radius: 10px;
      margin-left: 10px;

      &.validButton, &.cancelButton {
        visibility: visible;
      }

      &:hover {
        background: var(--grey-sur-hover);
      }
    }
  }

  @media screen and (max-width: 480px) {
    .purposes {
      width: 140px !important;

      .purposeEntry {
        height: unset !important;
      }
    }


    .pickerContainer {
      width: calc(100% - 150px) !important;
    }
  }
}