@use "../../../../../sedestral/sass/mixins/texts";

.componentProgressBarSemi {
  position: relative;
  height: 100%;
  width: 100%;

  @include texts.font("semi-bold");

  &.arc {
    .texts {
      justify-content: flex-end;
    }
  }

  .texts {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .value {
      span {
        color: var(--grey-text)
      }
    }

    .title {
      visibility: visible;
    }
  }
}