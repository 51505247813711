@use "../../../../../sedestral/sass/extends/inputs";
@use "../../../../../sedestral/sass/icons/icons";
@use "../../../../../sedestral/sass/mixins/texts";

.componentDateInterval {
  height: 100%;
  width: 100%;

  .arrowIcon {
    height: 12px;
    width: 12px;
    margin-right: 5px;
    float: left;
    margin-top: 7px;
    margin-left: 5px;

    @include icons.ico("black-right-arrow");
  }

  .input {
    @include inputs.white;

    border: unset;
    height: 26px;
    border-radius: 9px;
    text-align: center;
    background: unset;
    width: calc(50% - 11px) !important;
    cursor: text;
    float: left;
    padding: 0 5px;

    @include texts.font("semi-bold");

    &:hover {
      background: var(--grey-sur);
    }

    &.First {
      visibility: visible;
    }

    &.Second {
      visibility: visible;
    }
  }

  span {
    font-size: 14px;
    padding: 5px;
    line-height: 26px;

    @include texts.font("semi-bold");
  }
}