@use "src/sedestral/sass/icons/icons";

.componentKeywordIntent {
  height: 100%;
  width: 100%;

  &.commercial {
    @include icons.ico("black-lineal-intent-commercial");
  }

  &.informational {
    @include icons.ico("black-lineal-intent-informational");
  }

  &.navigational {
    @include icons.ico("black-lineal-intent-navigational");
  }

  &.transactional {
    @include icons.ico("black-lineal-intent-transactional");
  }
}