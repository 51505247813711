@use "src/sedestral/sass/icons/icons";
@use "src/sedestral/sass/mixins/texts";

.componentKeywordIntentTooltip {
  .top {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 15px;

    .icon {
      height: 20px;
      width: 20px;
    }

    .name {
      font-size: 17px;

      @include texts.font("semi-bold");
    }
  }

  .contents {
    font-size: 14px;

    .name {
      font-size: 15px;

      @include texts.font("semi-bold");
    }
  }
}