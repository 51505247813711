@use "../../../../../../sedestral/sass/mixins/positioning";
@use "../../../../../../sedestral/sass/extends/inputs";
@use "../../../../../../sedestral/sass/icons/icons";
@use "../../../../../../sedestral/sass/mixins/texts";
@use "../../../../../../sedestral/sass/extends/buttons";

.globalDateCalendarPicker {
  height: 100%;
  width: 100%;

  .buttons {
    left: 5px;
    width: calc(100% - 12px);
    margin-top: 5px !important;
    margin-bottom: 6px;
    height: 30px !important;
    position: relative;

    @include positioning.flex-globally;

    .next, .previous {
      height: 24px;
      width: 24px;
      border-radius: 100%;
      position: absolute;
      float: left;

      @include buttons.round;
      @include positioning.flex-globally;

      &.previous {
        left: 0;

        .icon {
          @include icons.ico("black-left-bold");
        }
      }

      &.next {
        right: 0;

        .icon {
          @include icons.ico("black-right-bold");
        }
      }

      .icon {
        height: 12px;
        width: 12px;
      }
    }

    .month {
      padding: 5px 12px;
      border-radius: 20px;
      font-size: 15px;
      position: absolute;

      @include texts.font("bold");
    }
  }

  .entry {
    height: 20px;
    width: 14.2%;
    border-radius: 100%;
    float: left;

    @include positioning.flex-globally;
  }

  .entryTitle {
    font-size: 11px;
    height: 25px;
    color: var(--grey-thumb);

    @extend .entry;
    @include texts.font("semi-bold");
  }

  .entryDate {
    font-size: 12px;
    height: 28px;

    @extend .entry;
    @include texts.font("semi-bold");

    .round {
      height: 26px;
      width: 26px;

      @include buttons.round;
      @include positioning.flex-globally;

      &:hover {
        color: var(--black) !important;
        background: var(--grey-sur);
      }
    }

    &.Previous {
      color: var(--grey-thumb);
    }


    &.Selected {
      .round {
        background: var(--blue-mep) !important;
        color: var(--white) !important;
      }
    }


    &.Interval {
      .round {
        background: var(--grey-sur) !important;
      }
    }

    &.IntervalSelected {
      .round {
        border: 2px solid var(--white) !important;
        box-shadow: 0 0 0 2px var(--white);
        background: var(--black) !important;
        color: var(--white) !important;
      }
    }

    &.IntervalSameDay {
      .round {
        box-shadow: 0 0 0 2px var(--blue-mep);

      }
    }
  }

  .names {
    visibility: visible;
  }

  .days {
    height: calc(100% - 40px);

    div[emptyBasic] {
      top: -20px;
      position: relative;
    }
  }
}