@use "src/sedestral/sass/mixins/texts";
@use "src/sedestral/sass/extends/boxs";
@use "src/sedestral/sass/icons/icons";

.componentClusterHoneycombKeyword {
  .top {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    .name {
      font-size: 16px;

      @include texts.font("semi-bold");
    }

    .close {
      margin-left: auto;
      height: 30px;
      flex: 0 0 30px;
      width: 30px;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &:hover {
        background: var(--grey-sur);
      }

      .icon {
        height: 12px;
        width: 12px;

        @include icons.ico("black-close");
      }
    }
  }

  .stats {
    display: flex;
    margin-bottom: 15px;
    gap: 8px;

    .bar {
      width: 1px;
      background: var(--grey-sur);
      height: 44px;
      position: relative;
      flex: 0 0 1px;
    }

    .stat {
      .label {
        color: var(--grey-text);
        font-size: 12px;
        text-transform: uppercase;
        margin-bottom: 8px;
      }

      .flex {
        width: 100%;
        display: flex;
        justify-content: center;

        .intent {
          height: 18px;
          width: 18px;
          position: relative;
        }

        .semi {
          height: 32px;
          width: 32px;
          margin-top: -4px;
          position: absolute;
        }
      }

      .value {
        font-size: 18px;

        @include texts.font("semi-bold");
      }
    }

    .coverage {
      height: 44px;
      width: 44px;
      position: relative;
      align-items: center;
      justify-content: center;
      display: flex;
      margin-left: auto;

      .coverIcon {
        width: 39px;
        height: 39px;

        &.iconRANKED {
          @include icons.ico("colored-topical-map-ranked");
        }

        &.iconPUBLISHED {
          @include icons.ico("colored-topical-map-published");
        }

        &.iconTRANSFERRED {
          @include icons.ico("colored-topical-map");
        }

        &.iconNONE {
          @include icons.ico("colored-topical-map-white");
        }

        &.iconDRAFT {
          @include icons.ico("colored-topical-map-draft");
        }
      }

      .intent {
        width: 39px;
        height: 39px;
        position: relative;
      }
    }
  }

  .create {
    background: var(--black);
    color: var(--white);
    font-size: 14px;
    align-items: center;
    padding: 5px 10px;
    display: flex;
    margin-left: auto;
    width: fit-content;
    gap: 15px;
    border-radius: 17px;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }

    .icon {
      height: 12px;
      width: 12px;
      filter: var(--icon-white);

      @include icons.ico("black-write-ai");
    }
  }
}